import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—December 2008" />
	<NewsHeader />
    <h2>December 2008</h2>



	<h3 className="blogdate">
	Tuesday, December 09, 2008
	</h3>

	<h4 className="blogitemtitle">
	Deadbeat Blues added to ILike, Facebook
	</h4>
		<div className="blogitembody">
		<p>Thanks to a request from Lance Rattie via Facebook and ILike, I have
uploaded my song <em>Deadbeat Blues</em> from <em>Seventeen Oh-Two Oh-Six</em>!
</p>
<p>
  It's a bit of a &quot;colorful&quot; tune - nevertheless it got me selected as a
  Best Newcomer Finalist in the 2005 Calgary Folk Music Festival
  Songwriting Contest. Swing by my ILike or Facebook page to hear it :)
</p>
<p>
  Ciao for now,
</p>
<p>
  Jesse
</p>
		</div>
		<div className="blogitemfooter">
		<p>posted by Jesse @ 11:50 +1300</p>
		</div>




	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
